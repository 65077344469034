export enum UserManagerMode {
    Owners = 'Owners',
    Members = 'Members'
  }
  export enum OfficeApps {
    Outlook = 0,
    SharePoint = 1,
    Teams = 2,
    Yammer = 3,
  }
  export enum ComplianceStates{
    NotScanned = 0,
    Compliant = 1,
    LessThan2Owners = 2,
    Expired = 3,
    ExpiredAndLessThan2Owners = 4
  }
  export enum ComplianceRule{
    None = "None",
    TwoOwnerEnforcement = "TwoOwnerEnforcement",
    LeaseExpiration = "LeaseExpiration"
  }
  export enum ChangeType{
    UpdateLease = "UpdateLease",
    UpdateLeaseInternal = "UpdateLeaseInternal",
    AddOwner = "AddOwner",
    RemoveOwner = "RemoveOwner",
    DeleteGroup = "DeleteGroup",
    DeleteSite = "DeleteSite"
  }
  export enum SensitivityLabel{
    InternalOnly = "Internal Only",
    InternalExternal = "Internal - External",
    None = ""
  }