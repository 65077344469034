import {
  MgtPerson 
} from "@microsoft/mgt-react";
import { left } from "@popperjs/core";
import React from "react";
import { ChangeType} from "../enums";
import { directoryObject } from "../objects/directoryObj";
import { resource } from "../objects/resource";
import {
  ConvertBytesToMB,
  ConvertDateStringToLongDate
} from "../utils";
import M365Card from "./m365-card";
import SPOSiteCard from "./spo-site-card";
import Swal from "sweetalert2";
import {
  addM365GroupOwner,
  removeM365GroupOwner
} from "../services/resource-manager";
import { useMsal } from "@azure/msal-react";
import { apiScopes } from "../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { errorsTypes } from "../errorTypes";
import StatusCode from "status-code-enum";
import { toast } from "react-toastify";
import { OwnerManager } from "./owner-manager";

interface resourceDetailProps {
  ownedResource: resource;
  baseTabId: string;
  refreshDatasetFunction: (resourceID: string, changeType: ChangeType, value: any) => void;
}
/**
 * Represents the details of a single M365 or SPO resources, displayed by the resources grid
 */
export function ResourceDetailM365(props: resourceDetailProps) {
  MgtPerson.config.useContactApis = true;
  const { instance, accounts } = useMsal();
  // The TooltipHost root uses display: inline by default.
  // If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.

  const accessTokenRequest = {
    scopes: apiScopes.resourceManagementScopes,
    account: accounts[0],
  };
  //let remainingDays: number = 0;

  function addGroupOwner(ownerList:directoryObject[], resourceId: string):void{
    ownerList.forEach(currOwner => {
      Swal.showLoading();
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(async (accessTokenResponse) => {
          const toastLeaseRenewId = toast.loading(`Adding ${currOwner.userPrincipalName}`);
          let operationResult = await addM365GroupOwner(
            accessTokenResponse.accessToken,
            resourceId,
            currOwner.id as string
          );
          Swal.close();
          if (operationResult === StatusCode.SuccessOK) {
            props.refreshDatasetFunction(
              resourceId,
              ChangeType.AddOwner,
              currOwner.userPrincipalName as string
            );
            let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
            try {
              let buttonElement = document.getElementById(collapseButtonId);
              buttonElement?.click();
            } catch {
              console.log("Could not trigger tab collapse");
            }
            toast.update(toastLeaseRenewId, {
              render: "Operation Complete",
              type: "success",
              isLoading: false,
              autoClose: 8000,
            });
          } else {
            toast.error(errorsTypes.somethingWentWrong.errorSubtitle, {
              type: "error",
              isLoading: false,
              autoClose: 8000,
            });
          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            Swal.close();
            Swal.fire({
              titleText: errorsTypes.sessionExpired.errorTitle,
              text: errorsTypes.sessionExpired.errorSubtitle,
              icon: "error",
              willClose: () => {
                instance.acquireTokenRedirect(accessTokenRequest);
              },
            });
          }
          console.log(error);
        });
    });
  }

  function removeGroupOwner(ownerList: directoryObject[], resourceId: string): void {
    let currentUserEmail = (window as any).CURRENT_USER_EMAIL;
    let ownerListFilterResult = ownerList.filter((owner) => (owner.userPrincipalName as string) === currentUserEmail);
    let filteredOwners = ownerList.filter((owner) => (owner.userPrincipalName as string) !== currentUserEmail);
    Swal.showLoading();
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then(async (accessTokenResponse) => {
        // Map over the array and call the async function for each element
        const promises = filteredOwners.map(async (owner) => {
          const toastLeaseRenewId = toast.loading(`Removing ${owner.userPrincipalName}`);
          let operationResult = await removeM365GroupOwner(
            accessTokenResponse.accessToken,
            resourceId,
            owner.id as string
          );
          Swal.close();
          if (operationResult === StatusCode.SuccessOK) {
            props.refreshDatasetFunction(
              resourceId,
              ChangeType.RemoveOwner,
              owner.userPrincipalName as string
            );
            let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
            try {
              let buttonElement = document.getElementById(collapseButtonId);
              buttonElement?.click();
            } catch {
              console.log("Could not trigger tab collapse");
            }

            toast.update(toastLeaseRenewId, {
              render: "Operation Complete",
              type: "success",
              isLoading: false,
              autoClose: 8000,
            });
          } else {
            toast.error(errorsTypes.somethingWentWrong.errorSubtitle, {
              type: "error",
              isLoading: false,
              autoClose: 8000,
            });
          }
        });
        await Promise.all(promises).then(async () => {
          if (ownerListFilterResult.length > 0) {
            const toastLeaseRenewId = toast.loading(
              `Removing ${ownerListFilterResult[0].userPrincipalName as string}`
            );
            let operationResult = await removeM365GroupOwner(
              accessTokenResponse.accessToken,
              resourceId,
              ownerListFilterResult[0].id as string
            );
            Swal.close();
            if (operationResult === StatusCode.SuccessOK) {
              let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
              try {
                let buttonElement = document.getElementById(collapseButtonId);
                buttonElement?.click();
              } catch {
                console.log("Could not trigger tab collapse");
              }
              props.refreshDatasetFunction(
                resourceId,
                ChangeType.DeleteGroup,
                ownerListFilterResult[0].userPrincipalName as string
              );

              toast.update(toastLeaseRenewId, {
                render: "Operation Complete",
                type: "success",
                isLoading: false,
                autoClose: 8000,
              });
            } else {
              toast.error(errorsTypes.somethingWentWrong.errorSubtitle, {
                type: "error",
                isLoading: false,
                autoClose: 8000,
              });
            }
          }
        });
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          Swal.close();
          Swal.fire({
            titleText: errorsTypes.sessionExpired.errorTitle,
            text: errorsTypes.sessionExpired.errorSubtitle,
            icon: "error",
            willClose: () => {
              instance.acquireTokenRedirect(accessTokenRequest);
            },
          });
        }
        console.log(error);
      });
  }
  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
      <div className="row">
        <ul className="nav nav-tabs">
          <li className="nav-item" role="presentation" style={{ borderColor: "#E9E9E9" }}>
            <button
              type="button"
              id={props.baseTabId + "-overview"}
              className="nav-link active nav-link-resource"
              data-bs-toggle="tab"
              data-bs-target={"#" + props.baseTabId + "-overview-content"}
              role="tab"
              aria-controls="home"
              aria-selected="true"
              style={{ fontWeight: "bold" }}
            >
              <i className="intelicon-contacts-outlined" style={{ fontWeight: "bold" }}></i>{" "}
              Overview
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id={props.baseTabId + "-owners"}
              className="nav-link nav-link-resource"
              data-bs-toggle="tab"
              data-bs-target={"#" + props.baseTabId + "-owners-content"}
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              style={{ fontWeight: "bold" }}
            >
              <i className="intelicon-primary-user-person-solid" style={{ fontWeight: "bold" }}></i>{" "}
              Owners
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id={props.baseTabId + "-activity"}
              className="nav-link nav-link-resource"
              data-bs-toggle="tab"
              data-bs-target={"#" + props.baseTabId + "-activity-content"}
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              style={{ fontWeight: "bold" }}
            >
              <i className="intelicon-activity-outlined" style={{ fontWeight: "bold" }}></i>{" "}
              Activity
            </button>
          </li>
        </ul>
        <div
          className="tab-content border-end border-bottom border-start"
          style={{ backgroundColor: "#E9E9E9" }}
        >
          <div
            className="tab-pane fade active show "
            id={props.baseTabId + "-overview-content"}
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {props.ownedResource.isGroupConnected ? (
              <M365Card ownedResource={props.ownedResource} />
            ) : (
              <SPOSiteCard ownedResource={props.ownedResource} />
            )}

            <br />
          </div>
          <div
            className="tab-pane"
            id={props.baseTabId + "-owners-content"}
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div style={{ paddingLeft: "14px" }}>
              <br />
              <OwnerManager
                currentOwners={props.ownedResource.owners}
                resourceId={props.ownedResource.azureAdId}
                isM365={true}
                addFunction={addGroupOwner}
                removeFunction={removeGroupOwner}
                isAGS={props.ownedResource.agsTracked}
              ></OwnerManager>
              <br />
            </div>
          </div>
          <div
            className="tab-pane"
            id={props.baseTabId + "-activity-content"}
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div
              style={{
                display: !props.ownedResource.activityDataAvailable === false ? "none" : "grid",
              }}
            >
              <br />
              <div className="alert alert-light" role="alert" style={{ backgroundColor: "white" }}>
                <h4 className="alert-heading">No activity was found for this space</h4>
                <p></p>
                <hr />
                <p className="mb-0">
                  Collaboration Spaces created 24 hours ago or less might no have activity records
                  available, please wait 24-48 hours for the information to be available
                </p>
              </div>
            </div>
            <div
              style={{
                display: props.ownedResource.activityDataAvailable === false ? "none" : "grid",
              }}
            >
              <br />

              <table
                className="table table-bordered"
                style={{ display: props.ownedResource.groupActivity === null ? "none" : "table" }}
              >
                <tbody>
                  <tr style={{ backgroundColor: "#0078d4", color: "white" }}>
                    <th>Group Activity Summary</th>
                  </tr>
                  <tr style={{ backgroundColor: "white", textAlign: "justify" }}>
                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Member Count:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.groupActivity?.memberCount}
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            External Member Count:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.groupActivity?.externalMemberCount}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Last Activity Date:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={ConvertDateStringToLongDate(
                              props.ownedResource.groupActivity?.lastActivityDate
                            )}
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Yammer Posted Message Count:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={
                              props.ownedResource.resourceType === "Yammer"
                                ? props.ownedResource.groupActivity?.yammerPostedMessage
                                : "Not available"
                            }
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Yammer Liked Msg Count:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={
                              props.ownedResource.resourceType === "Yammer"
                                ? props.ownedResource.groupActivity?.yammerLikedMessageCount
                                : "Not available"
                            }
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Report Period:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={"90 Days"}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            File Count:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.groupActivity?.sharePointTotalFileCount}
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Active File Count
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={
                              props.ownedResource.groupActivity?.sharePointActiveFileCount === ""
                                ? "0"
                                : props.ownedResource.groupActivity?.sharePointActiveFileCount
                            }
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Allocated Storage:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value="25TB"
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Storage Used:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={
                              ConvertBytesToMB(
                                props.ownedResource.groupActivity
                                  ?.sharePointSiteStorageByte as string
                              ) + "MB"
                            }
                          />
                        </div>
                      </div>
                      <br />
                    </div>
                  </tr>
                </tbody>
              </table>
              <table
                className="table table-bordered"
                style={{ display: props.ownedResource.teamsActivity === null ? "none" : "table" }}
              >
                <tbody>
                  <tr style={{ backgroundColor: "#5b5fc7", color: "white" }}>
                    <th>Teams Activity Summary</th>
                  </tr>
                  <tr style={{ backgroundColor: "white", textAlign: "justify" }}>
                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Team Id:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.teamsActivity?.teamID}
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Team Name:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.teamsActivity?.teamName}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Team Type:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.teamsActivity?.teamType}
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Is Deleted:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.teamsActivity?.isDeleted}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Active Users:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.teamsActivity?.activeUsers}
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Active Channels:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.teamsActivity?.activeChannels}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Channel Messages:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.teamsActivity?.channelMessages}
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Reactions:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.teamsActivity?.reactions}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Meetings Organized:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.teamsActivity?.meetingsOrganized}
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Guests:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.teamsActivity?.guests}
                          />
                        </div>
                      </div>
                      <br />
                    </div>
                  </tr>
                </tbody>
              </table>
              <table
                className="table table-bordered"
                style={{ display: props.ownedResource.yammerActivity === null ? "none" : "table" }}
              >
                <tbody>
                  <tr style={{ backgroundColor: "#0078d4", color: "white" }}>
                    <th>Viva Engage Activity Summary</th>
                  </tr>
                  <tr style={{ backgroundColor: "white", textAlign: "justify" }}>
                    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Member Count:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.yammerActivity?.memberCount}
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Posted Count:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.yammerActivity?.postedCount}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Read Count:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.yammerActivity?.readCount}
                          />
                        </div>
                        <div className="col-2 col-lg-1">
                          <label
                            style={{
                              display: "inline-block",
                              fontWeight: "bold",
                              float: left,
                              inlineSize: "100%",
                              textAlign: "start",
                              paddingLeft: "4%",
                            }}
                            className="col-sm-2 col-form-label"
                          >
                            Liked Count:
                          </label>
                        </div>
                        <div className="col-4 col-lg-5">
                          <input
                            style={{ display: "inline-block" }}
                            type="text"
                            className="form-control"
                            disabled
                            value={props.ownedResource.yammerActivity?.likedCount}
                          />
                        </div>
                      </div>
                      <br />
                    </div>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceDetailM365;
