import {
  IContextualMenuItemProps,
  TooltipHost,
  CommandBar,
  IButtonProps,
  ContextualMenuItem,
  ITooltipHostStyles,
  ITooltipProps,
  TooltipDelay,
  DirectionalHint
} from "@fluentui/react";
import ResourceDetailM365 from "./resource-detailm365";
import ResourceDetailSPO from "./resource-detailspo";
import {
  checkResourceCompliance,
  drawTextInCanvas,
  generateRandomString,
  getFormattedDate,
  getResourceLink,
} from "../utils";
import { resource } from "../objects/resource";
import { createRoot } from 'react-dom/client';
import ResourceNavlink from "./resource-navlink";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { apiScopes, msalConfig } from "../authConfig";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { ComplianceField } from "./compliance-field";
import { ChangeType, SensitivityLabel, ComplianceRule } from "../enums";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { deletem365Group, deleteSPOSite } from "../services/resource-manager";
import { errorsTypes } from "../errorTypes";
import StatusCode from "status-code-enum";
import { toast } from "react-toastify";
import { ExpirationDateField } from "./date-field";
import {
  extendResourceLease,
  updateSensitivityLabel,
} from "../services/resource-manager";

interface ownedResources {
  ownedResources: Array<resource>;
  resourceType: string;
  refreshDatasetFunction: (resourceID: string, changeType: ChangeType, value: any) => void;
}
interface resourceDetailProps {
  ownedResource: resource;
  baseTabId: string;
  refreshDatasetFunction: (resourceID: string, changeType: ChangeType, value: any) => void;
}
/**
 * Grid component, displays the list of resources on an accordion table
 */
export function ResourceGrid(props: ownedResources) {
  const { instance, accounts } = useMsal();
  const accessTokenRequest = {
    scopes: apiScopes.resourceManagementScopes,
    account: accounts[0],
  };
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
  function getExtClassificationToolTip(setupToolFlag:string): ITooltipProps {
    const tooltipProps: ITooltipProps = {
      onRenderContent: () => (
        <div>
          <p>
            This workspace is managed by {setupToolFlag === "DMS" ? " the Disclosure Management System " : " Intel Foundy Services "}<a href={setupToolFlag === "DMS" ? "https://wiki.ith.intel.com/display/disclosures/Disclosure+Management+System+Home":"https://circuit.intel.com/content/it/it-support/topic-pages/others/ifs-intel-foundry-services-portal.html"} target="_blank" rel="noopener noreferrer">Click for more information</a>
          </p>
        </div>
      ),
    };
    return tooltipProps;
  }
  async function redirectoDeletePage(currentResource: resource, currentIndex: number) {
    let confirmationCode = generateRandomString(6);
    let htmlDialogContent = (
      <div>
        <p>
          You are about to delete the '{currentResource.displayName}' site. This will delete all
          SharePoint content in the site. Please backup any files or other content before
          proceeding. Do you want to continue?
        </p>
        <br></br>
        <div className="container card">
          <canvas id="secretCodeCanvas" width="auto" height="100"></canvas>
        </div>
      </div>
    );

    const { value: paramConfCode } = await Swal.fire({
      title: "Delete this resource",
      input: "text",
      inputLabel: "Enter the text displayed above:",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      allowEnterKey: false,
      allowOutsideClick: false,
      inputValidator: (value) => {
        if (!value) return "Please enter the confirmation code";
        else if (value !== confirmationCode) return "The confirmation code is incorrect";
        else return null;
      },
      confirmButtonText: "Delete",
      confirmButtonColor: "#CE0000",
      inputPlaceholder: "Enter the code here",
      html: renderToStaticMarkup(htmlDialogContent),
      didOpen: () => {
        drawTextInCanvas("secretCodeCanvas", confirmationCode);
      },
    });
    if (paramConfCode) {
      if (paramConfCode === confirmationCode) {
        const toastDeleteId = toast.loading(`Deleting the resouce: ${currentResource.displayName} , this might take 30 seconds or more`);
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then(async (accessTokenResponse) => {
            let deleteResult: number = 0;

            props.refreshDatasetFunction(
              currentResource.isGroupConnected
                ? currentResource.azureAdId
                : currentResource.siteUrl,
              currentResource.isGroupConnected
                ? ChangeType.DeleteGroup
                : ChangeType.DeleteSite,
              null
            );
            try {
              let collapseButtonId = "btn-expandResource" + props.resourceType + currentIndex;
              let buttonElement = document.getElementById(collapseButtonId);
              let isTabExpanded = buttonElement?.getAttribute("aria-expanded");
              if (isTabExpanded === "true") {
                buttonElement?.click();
              }
            } catch {
              console.log("Could not trigger tab collapse");
            }

            if (currentResource.isGroupConnected) {
              deleteResult = await deletem365Group(
                accessTokenResponse.accessToken,
                currentResource.azureAdId
              );
            } else {
              deleteResult = await deleteSPOSite(
                accessTokenResponse.accessToken,
                currentResource.siteUrl
              );
            }

            if (deleteResult === StatusCode.SuccessNoContent) {
              toast.update(toastDeleteId, { render: `Resource succesfully deleted: ${currentResource.displayName}`, type: "success", isLoading: false, autoClose: 8000 });
            } else {
              toast.update(toastDeleteId, { render: errorsTypes.internalServerError.errorSubtitle, type: "error", isLoading: false, autoClose: 8000 });
            }
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              toast.update(toastDeleteId, { render: `We encountered a problem while deleting: ${currentResource.displayName}. Please try again later or contact support. No data was deleted.`, type: "error", isLoading: false, autoClose: 8000 });
              instance.acquireTokenRedirect(accessTokenRequest);
            }
            console.log(error);
          });
      }
    }
  }

  function handleResourceDetailLoad(
    buttonElementID: string,
    containerID: string,
    currentResource: resource,
    currentArrayIndex: number
  ) {
    props.ownedResources.forEach((element) => {
      element.compliance = checkResourceCompliance(element);
    });
    const currentResourceDetailButton: HTMLElement | null =
      document.getElementById(buttonElementID);
    const msalInstance = new PublicClientApplication(msalConfig);
    if (!currentResourceDetailButton!.classList.contains("collapsed")) {
      const currentResourceDetailNode = document.getElementById(containerID)!;
      const root = createRoot(currentResourceDetailNode);
      if (currentResource.resourceType === "SPO") {
        root.render(
          <MsalProvider instance={msalInstance}>
            <ResourceDetailSPO
              ownedResource={currentResource}
              baseTabId={"tab-" + props.resourceType + currentArrayIndex}
              refreshDatasetFunction={props.refreshDatasetFunction}
            />
          </MsalProvider>
        );
      } else {
        root.render(
          <MsalProvider instance={msalInstance}>
            <ResourceDetailM365
              ownedResource={currentResource}
              baseTabId={"tab-" + props.resourceType + currentArrayIndex}
              refreshDatasetFunction={props.refreshDatasetFunction}
            />
          </MsalProvider>
        );
      }
    }
  }


  const CustomMenuItem: React.FunctionComponent<IContextualMenuItemProps> = props => {
    return <ContextualMenuItem {...props} />;
  };

  const overflowProps: IButtonProps = {
    menuProps: {
      contextualMenuItemAs: CustomMenuItem,
      // Styles are passed through to menu items here

      items: [], // CommandBar will determine items rendered in overflow
      isBeakVisible: true,
      beakWidth: 20,
    },
  };

  async function validateExternalResourceLease(props: resourceDetailProps): Promise<void> {
    Swal.fire({
      title: "Extending lease...",
      didOpen: () => {
        Swal.showLoading();
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then(async (accessTokenResponse) => {
            if (
              props.ownedResource.sensitivityLabel !== (SensitivityLabel.InternalOnly as string)
            ) {
              Swal.fire({
                title: "External Facing Workspace",
                text: "This workspace is external facing, that allows you to collaborate with people outside the intel network. After renewing the lease over this space do you still need it to be external facing?",
                showDenyButton: true,
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: "Yes, keep the workspace as external",
                denyButtonText: "No, convert to internal",
                cancelButtonText: "Cancel, I'll renew later",
              }).then(async (result) => {
                const toastLeaseRenewId = toast.loading("Updating resource lease");
                /* Read more about isConfirmed, isDenied below */
                if (result.isDenied) {
                  let sensitivityLabelChgResult = await updateSensitivityLabel(
                    accessTokenResponse.accessToken,
                    props.ownedResource.isGroupConnected ? props.ownedResource.azureAdId : props.ownedResource.siteUrl,
                    props.ownedResource.isGroupConnected,
                    SensitivityLabel.InternalOnly
                  );
                  let operationResult = await extendResourceLease(
                    accessTokenResponse.accessToken,
                    props.ownedResource.isGroupConnected ? props.ownedResource.azureAdId : encodeURIComponent(props.ownedResource.siteUrl),
                    props.ownedResource.isGroupConnected,
                  );
                  if (
                    operationResult === StatusCode.SuccessOK &&
                    sensitivityLabelChgResult === StatusCode.SuccessOK
                  ) {
                    props.refreshDatasetFunction(
                      props.ownedResource.isGroupConnected ? props.ownedResource.azureAdId : props.ownedResource.siteUrl,
                      ChangeType.UpdateLeaseInternal,
                      null
                    );
                    
                    let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
                    try {
                      let buttonElement = document.getElementById(collapseButtonId);
                      buttonElement?.click();
                    } catch {
                      console.log("Could not trigger tab collapse");
                    }
                    toast.update(toastLeaseRenewId, {
                      render: "Operation Complete",
                      type: "success",
                      isLoading: false,
                      autoClose: 8000,
                    });
                  } else {
                    toast.update(toastLeaseRenewId, {
                      render: errorsTypes.somethingWentWrong.errorSubtitle,
                      type: "error",
                      isLoading: false,
                      autoClose: 8000,
                    });
                  }
                } else if (result.isConfirmed) {
                  let operationResult = await extendResourceLease(
                    accessTokenResponse.accessToken,
                    props.ownedResource.isGroupConnected ? props.ownedResource.azureAdId : encodeURIComponent(props.ownedResource.siteUrl),
                    props.ownedResource.isGroupConnected
                  );
                  if (operationResult === StatusCode.SuccessOK) {
                    props.refreshDatasetFunction(
                      props.ownedResource.isGroupConnected ? props.ownedResource.azureAdId : props.ownedResource.siteUrl,
                      ChangeType.UpdateLease,
                      null
                    );
                    
                    let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
                    try {
                      let buttonElement = document.getElementById(collapseButtonId);
                      buttonElement?.click();
                    } catch {
                      console.log("Could not trigger tab collapse");
                    }
                    toast.update(toastLeaseRenewId, {
                      render: "Operation Complete",
                      type: "success",
                      isLoading: false,
                      autoClose: 8000,
                    });
                  } else {
                    toast.update(toastLeaseRenewId, {
                      render: errorsTypes.somethingWentWrong.errorSubtitle,
                      type: "error",
                      isLoading: false,
                      autoClose: 8000,
                    });
                  }
                } else if (result.isDismissed) {
                  toast.update(toastLeaseRenewId, {
                    render: "Operation Cancelled",
                    type: "info",
                    isLoading: false,
                    autoClose: 5000,
                  });
                  Swal.close();
                }
              });
            } else {

              const toastLeaseRenewId = toast.loading("Updating resource lease");

              let encodedUrl = encodeURIComponent(props.ownedResource.siteUrl);
              let operationResult = await extendResourceLease(
                accessTokenResponse.accessToken,
                props.ownedResource.isGroupConnected ? props.ownedResource.azureAdId : encodeURIComponent(props.ownedResource.siteUrl),
                props.ownedResource.isGroupConnected
              );
              Swal.hideLoading();
              Swal.close();
              if (operationResult === StatusCode.SuccessOK) {
                props.refreshDatasetFunction(
                  props.ownedResource.isGroupConnected ? props.ownedResource.azureAdId : encodeURIComponent(props.ownedResource.siteUrl),
                  ChangeType.UpdateLease,
                  null
                );
                let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
                try {
                  let buttonElement = document.getElementById(collapseButtonId);
                  buttonElement?.click();
                } catch {
                  console.log("Could not trigger tab collapse");
                }
                toast.update(toastLeaseRenewId, {
                  render: "Operation Complete",
                  type: "success",
                  isLoading: false,
                  autoClose: 8000,
                });
              } else {
                toast.update(toastLeaseRenewId, {
                  render: errorsTypes.somethingWentWrong.errorSubtitle,
                  type: "error",
                  isLoading: false,
                  autoClose: 8000,
                });
              }
            };
          }
          )
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              toast.error(errorsTypes.somethingWentWrong.errorSubtitle, {
                type: "error",
                isLoading: false,
                autoClose: 8000,
              });
            }
            console.log(error);
          });
      },
    });

  }
  function calculateCompliance(props: resourceDetailProps) {
    if(props.ownedResource.setupToolFlag !== ""){
      return true;
    }
    let twoOwnerEnformentCompliant = true,
    remainingDays = 0;
    for (let index = 0; index < props.ownedResource.compliance.length; index++) {
      if (
        props.ownedResource.compliance[index].complianceRule ===
        ComplianceRule.TwoOwnerEnforcement &&
        !props.ownedResource.compliance[index].isCompliant
      ) {
        twoOwnerEnformentCompliant = false;
      }
      
      if (props.ownedResource.compliance[index].complianceRule === ComplianceRule.LeaseExpiration) {
        remainingDays = props.ownedResource.compliance[index].remainingLeaseDays;
      }
    }
    return !(twoOwnerEnformentCompliant && remainingDays<180);
  }

  return (
    <div>
      <div
        style={{
          display: props.ownedResources.length === 0 ? "inline" : "none",
        }}
      >
        <div className="alert alert-light" role="alert">
          <h4 className="alert-heading">No resources to show</h4>
          <p></p>
          <hr />
          <p className="mb-0">
            You don't own any resource of this type at the moment. However, you have the option to
            use the{" "}
            <a
              target="_blank"
              href="https://apps.powerapps.com/play/e/c57452c8-1d59-4cc7-bb06-dd1bd68f06bf/a/44b067d3-ed7a-4b63-8933-c48670ed9a14?tenantId=46c98d88-e344-4ed4-8496-4ed7712e255d"
              rel="noreferrer"
            >
              setup tool
            </a>{" "}
            to create a new collaboration space.
          </p>
        </div>
      </div>
      <table
        className="accordion table table-responsive"
        style={{
          display: props.ownedResources.length > 0 ? "inline-table" : "none",
          width: "100%",
        }}
      >
        <thead>
          <tr style={{ textAlign: "justify" }}>
            <th scope="col"></th>
            <th scope="col">Workspace Name</th>
            <th scope="col">Sensitivity Label</th>
            <th scope="col">Workspace Type</th>
            <th scope="col">Status</th>
            <th scope="col">Expiration Date</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        {props.ownedResources?.map((resource, i) => {
          return (
            <tbody
              id={"tbl-resource" + props.resourceType + i}
              key={"tbl-resource" + props.resourceType + i}
            >
              <tr style={{ textAlign: "justify" }}>
                <td>
                  <button
                    id={"btn-expandResource" + props.resourceType + i}
                    title="Display additional information about this resource"
                    className="accordion-button accordion-button-plusminus collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#resource-row" + props.resourceType + i}
                    data-resourceid={
                      resource.isGroupConnected ? resource.azureAdId : resource.siteId
                    }
                    data-isconnected={resource.isGroupConnected ? true : false}
                    style={{ padding: "0px", margin: "0px" }}
                    onClick={(targetButton) =>
                      handleResourceDetailLoad(
                        targetButton.currentTarget.id,
                        "resourceDetailContainer-" + props.resourceType + i,
                        resource,
                        i
                      )
                    }
                  ></button>
                </td>
                <td>
                  <div style={{ paddingTop: "3px", margin: "auto", textAlign: "justify" }}>
                    {resource.displayName !== "" ? resource.displayName : resource.siteUrl}{" "}
                    {resource.setupToolFlag !== "" ? (
                      <TooltipHost
                        tooltipProps={getExtClassificationToolTip(resource.setupToolFlag)}
                        delay={TooltipDelay.zero}
                        directionalHint={DirectionalHint.bottomCenter}
                        styles={hostStyles}
                      >
                        <span className="badge rounded-pill bg-primary">
                          {resource.setupToolFlag}
                        </span>
                      </TooltipHost>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
                <td>
                  <div style={{ paddingTop: "3px" }}>
                    <TooltipHost content="Sensitivity labels let you classify and protect your organization's data, while making sure that user productivity and their ability to collaborate isn't hindered.">
                      {resource.sensitivityLabel === "" ? "Not Set" : resource.sensitivityLabel}
                    </TooltipHost>
                  </div>
                </td>
                <td>
                  <ResourceNavlink
                    resourceId={resource.azureAdId}
                    resourceType={resource.resourceType}
                    resourceUrl={getResourceLink(resource)}
                  />
                </td>
                <td>
                  <ComplianceField complianceResults={resource.compliance}></ComplianceField>
                </td>
                <td>
                  <ExpirationDateField
                    lastActivityDate={resource.lastUpdatedFormatted}
                    complianceResults={resource.compliance}
                    formatedComplianceDate={getFormattedDate(resource.renewDateFormatted, false)}
                    sensitivityLabel={resource.sensitivityLabel}
                    setupToolFlag={resource.setupToolFlag}
                  />
                </td>
                <td>
                  {/**  <TooltipHost
                    tooltipProps={getDeleteButtonTooltip(resource.agsTracked)}
                    delay={TooltipDelay.zero}
                    directionalHint={DirectionalHint.bottomCenter}
                    styles={hostStyles}
                  >*/}
                  {/* <button
                      type="button"
                      className="btn btn-danger me-1"
                      onClick={async () => {
                        await redirectoDeletePage(resource, i);
                      }}
                    >
                      Delete
                    </button>
                     */}
                  <CommandBar
                    style={{ padding: "0px" }}
                    overflowButtonProps={overflowProps}
                    items={[]}
                    overflowItems={[
                      {
                        key: "rename",
                        text: "Renew",
                        onClick: () => {
                          validateExternalResourceLease({
                            ownedResource: resource,
                            baseTabId: "tab-" + props.resourceType + i,
                            refreshDatasetFunction: props.refreshDatasetFunction,
                          });
                        },
                        iconProps: { iconName: "Edit" },
                        disabled: calculateCompliance({
                          ownedResource: resource,
                          baseTabId: "tab-" + props.resourceType + i,
                          refreshDatasetFunction: props.refreshDatasetFunction,
                        }),
                      },
                      {
                        key: "delete",
                        text: "Delete",
                        onClick: () => {
                          redirectoDeletePage(resource, i);
                        },
                        iconProps: { iconName: "Delete" },
                      },
                    ]}
                    styles={{
                      root: {
                        padding: 0,
                        color: "#0054ae",
                      },
                    }}
                  />

                  {/* </TooltipHost>*/}
                </td>
              </tr>
              <tr data-bs-parent=".table" style={{ borderColor: "transparent" }}>
                <td colSpan={8} style={{ padding: "0px" }}>
                  <div
                    id={"resource-row" + props.resourceType + i}
                    className="collapse"
                    data-bs-parent=".table"
                  >
                    <div className="accordion-body" style={{ padding: "0" }}>
                      <br />
                      <div id={"resourceDetailContainer-" + props.resourceType + i}></div>
                      <br />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );


}


