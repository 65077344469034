/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Person, PersonCardInteraction, ViewType } from "@microsoft/mgt-react";
import IntelLogo from "../logo.svg";
import { useBoolean } from "@fluentui/react-hooks";
import { ActivityItem, Icon, Separator, mergeStyleSets ,Panel, PanelType} from "@fluentui/react";
import { useState } from "react";
import { activityLog } from "../objects/activityLog";
import { calculateTimePassed } from "../utils";
import { apiScopes, m365AppsBaseLinks } from "../authConfig";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { Bounce, toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { addUserFeedBackEntry } from "../services/resource-manager";
import StatusCode from "status-code-enum";

/**
 * Navbar component, has navigations links and implements a persona component to display current user data
 */
const MainNavbar = (props: { userName: string; userActivity: Array<activityLog> }) => {
  const { instance, accounts } = useMsal();
  const accessTokenRequest = {
    scopes: apiScopes.resourceManagementScopes,
    account: accounts[0],
  };
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [notificationsPending, setNotificationsPending] = useState<boolean>(true);

  const classNames = mergeStyleSets({
    exampleRoot: {
      marginTop: "20px",
    },
    nameText: {
      fontWeight: "bold",
    },
  });

  async function openFeedbackForm() {
    let numericValue: number = 0;
    let textValue: string = "";
    let htmlDialogContent = (
      <div>
        <p>How would you rate your experience?</p>

        <div
          className="rate swal2-input"
          style={{
            width: "100%",
            margin: "0",
            fontSize: "xx-large",
            borderWidth: "0",
            boxShadow: "none",
          }}
        >
          <input type="radio" id="star5" name="userFeedbackScore" value="5" />
          <label
            htmlFor="star5"
            style={{ width: "20%" }}
            data-bs-toggle="tooltip" 
            data-bs-placement="top"
            title="Amazing"
          ></label>
          <input type="radio" id="star4" name="userFeedbackScore" value="4" />
          <label
            htmlFor="star4"
            style={{ width: "20%" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Very Good"
          ></label>
          <input type="radio" id="star3" name="userFeedbackScore" value="3" />
          <label
            htmlFor="star3"
            style={{ width: "20%" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Good"
          ></label>
          <input type="radio" id="star2" name="userFeedbackScore" value="2" />
          <label
            htmlFor="star2"
            style={{ width: "20%" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Average"
          ></label>
          <input type="radio" id="star1" name="userFeedbackScore" value="1" checked/>
          <label
            htmlFor="star1"
            style={{ width: "20%" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Needs Work"
          ></label>
        </div>
        <br></br>
        <p>Do you have any additional comment??</p>
        <textarea
          id="txtUserFeedbackComments"
          style={{ width: "100%", margin: "0" }}
          className="swal2-textarea"
          rows={5}
          placeholder="Enter it here"
          maxLength={500}
        ></textarea>
      </div>
    );
    Swal.fire({
      title: "We value your feedback",
      html: renderToStaticMarkup(htmlDialogContent),
      showCancelButton: true,
      confirmButtonText: "Submit",
      preConfirm: () => {
        const selectedRadio = document.querySelector('input[name="userFeedbackScore"]:checked') as HTMLInputElement;
        const textareaElement = document.getElementById("txtUserFeedbackComments") as HTMLTextAreaElement;

        if (selectedRadio) {
          numericValue = Number(selectedRadio.value);
        }
        if (textareaElement) {
          textValue = textareaElement.value;
        }
        if(numericValue !== 0 || textValue !== ""){
          instance
          .acquireTokenSilent(accessTokenRequest)
          .then(async (accessTokenResponse) => {
            let operationResult = await addUserFeedBackEntry(
              accessTokenResponse.accessToken,
              numericValue,
              textValue
            );
            if (operationResult !== StatusCode.SuccessOK) {
              console.error("Error while registering user feedback");
            }
          })
          .catch((error) => {
            console.error(error);
          });
        }
        toast('⭐ Thank your for your valuable feedback', {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
      },
    });
  }

  function getResourceActivityLink(activityEntry: activityLog, resourceConnected: boolean): string {
    if (resourceConnected) {
      return `/?isconnected=true&resourceid=${activityEntry.impactedResourceID}`;
    } 
    else {
      if(activityEntry.siteID === ""){
        return activityEntry.impactedResourceID;
      }
      else{
        return `/?isconnected=false&resourceid=${activityEntry.siteID}`;
      }
    }
  }

  function parseActivityList(): Array<any> {
    let tempArray: Array<any> = [];
    let tempArrayCount: number = 1;
    props.userActivity.forEach((activityEntry) => {
      let activityIconTemp: string = "Message";
      let activityDescriptionTemp = "";
      let timeStampTemp = calculateTimePassed(activityEntry.activityDateTime);
      let activityDetail: JSX.Element[] = [];

      if (
        activityEntry.actionTaken === "AddOwnerM365" ||
        activityEntry.actionTaken === "AddOwnerSPO"
      ) {
        activityIconTemp = "AddFriend";
        activityDescriptionTemp = "A new owner or admin was added to a resource";
        activityDetail = [
          <span key={1}>
            <b>{activityEntry.newValue}</b> was added as an owner or admin of{" "}
            <a
              href={getResourceActivityLink(activityEntry,activityEntry.actionTaken === "AddOwnerM365" )}
            >
              one of your resources
            </a>
          </span>,
        ];
      } else if (
        activityEntry.actionTaken === "RemoveOwnerM365" ||
        activityEntry.actionTaken === "RemoveOwnerSPO"
      ) {
        activityIconTemp = "UserRemove";
        activityDescriptionTemp = "An owner or admin was removed from a resource";
        activityDetail = [
          <span key={1}>
            <b>{activityEntry.oldValue}</b> was removed as an owner or admin of{" "}
            <a
              href={getResourceActivityLink(activityEntry,activityEntry.actionTaken === "RemoveOwnerM365" )}
            >
              one of your resources
            </a>
          </span>,
        ];
      } else if (
        activityEntry.actionTaken === "LeaseExtendM365" ||
        activityEntry.actionTaken === "LeaseExtendSPO"
      ) {
        activityIconTemp = "Clock";
        activityDescriptionTemp = "The lease on one resource was extended";
        activityDetail = [
          <span key={1}>
            6 months of additional resource lease were added to{" "}
            <a
              href={getResourceActivityLink(activityEntry,activityEntry.actionTaken === "LeaseExtendM365" )}
            >
              one of your resources
            </a>
          </span>,
        ];
      } else if (
        activityEntry.actionTaken === "UpdateSensitivityLabelM365" ||
        activityEntry.actionTaken === "UpdateSensitivityLabelSPO"
      ) {
        activityIconTemp = "Label";
        activityDescriptionTemp = "A sensitivity label was changed";
        activityDetail = [
          <span key={1}>
            The sensitivity label changed from {activityEntry.oldValue} to {activityEntry.newValue}{" "}
            for{" "}
            <a
              href={getResourceActivityLink(activityEntry,activityEntry.actionTaken === "UpdateSensitivityLabelM365" )}
            >
              one of your resources
            </a>
          </span>,
        ];
      } else if (
        activityEntry.actionTaken === "DeleteM365Group" ||
        activityEntry.actionTaken === "DeleteSPOSite"
      ) {
        activityIconTemp = "Trash";
        activityDescriptionTemp = "A resource was deleted";
        activityDetail = [
          <span key={1}>
            You deleted a{" "}
            {activityEntry.actionTaken === "DeleteM365Group"
              ? "connected resource (Yammer Community, Team or SharePoint site"
              : "SharePoint site"}
            : {activityEntry.oldValue?.replace(`${m365AppsBaseLinks.sharepoint}/sites/`,"")}
          </span>,
        ];
      }

      tempArray.push({
        key: tempArrayCount,
        activityDescription: activityDescriptionTemp,
        activityIcon: <Icon iconName={activityIconTemp} />,
        comments: activityDetail,
        timeStamp: timeStampTemp,
      });
    });
    return tempArray;
  }

  function toggleActivityPanel(): void {
    openPanel();
    setNotificationsPending(false);
  }
  let activityLogList = parseActivityList();
  return (
    <header>
      <Panel
        headerText="Recent Activity"
        type={PanelType.smallFixedFar}
        isOpen={isOpen}
        onDismiss={dismissPanel}
        onOpen={()=>{
          const button = document.getElementById('btnActivityPanelToggle');
          if (button instanceof HTMLElement) {
            button.blur();
          }
        }}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <Separator></Separator>
        <div>
          {activityLogList.map((item: { key: string | number }) => (
            <ActivityItem {...item} key={item.key} className={classNames.exampleRoot} />
          ))}
        </div>
      </Panel>
      <nav className="navbar navbar-expand-xl navbar-dark bg-primary">
        <div className="container-fluid">
          <style></style>
          <a className="navbar-brand" href="https://m365management.intel.com/">
            <img
              className="d-inline-block align-text-top me-2"
              height="23"
              src={IntelLogo}
              alt="Intel"
            />
            Workspace Management Tool <span style={{ fontSize: "10px" }}>1.0.17</span>
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a
                  className="nav-link text-nowrap"
                  href="https://apps.powerapps.com/play/e/c57452c8-1d59-4cc7-bb06-dd1bd68f06bf/a/44b067d3-ed7a-4b63-8933-c48670ed9a14?tenantId=46c98d88-e344-4ed4-8496-4ed7712e255d"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="intelicon-new-document-outlined fs-5"></i>
                  New Workspace
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-nowrap"
                  href="https://intel.sharepoint.com/sites/O365Help/SitePages/Workspace-Management-Tool.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="intelicon-help-outlined fs-5"></i>
                  Help
                </a>
              </li>
              <li className="nav-item">
                <a
                  id="btnOpenFeedbackForm"
                  className="nav-link text-nowrap"
                  href="javascript:void(0)"
                  onClick={async ()=>{openFeedbackForm()}}
                  rel="noreferrer"
                >
                  <i className="intelicon-idea-outlined fs-5"></i>
                  Feedback
                </a>
              </li>
            </ul>
            <ul className="navbar-nav justify-content-end" style={{ marginRight: "5px" }}>
              <button
                type="button"
                className="btn nav-link position-relative shadow-none"
                style={{ textAlign: "left" }}
                onClick={toggleActivityPanel}
                id="btnActivityPanelToggle"
              >
                <i className="intelicon-notification fs-5" style={{ paddingTop: "5px" , marginTop:"3px"}}></i>
                <span className="d-none d-lg-inline-block d-md-inline-block d-sm-inline-block d-xs-inline-block d-xl-none">
                  Notifications
                </span>
                <div
                  id="navbarPillContainer"
                  style={{ display: notificationsPending ? "inline-block" : "none" }}
                >
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                    style={{ fontSize: "xx-small", marginTop: "10px" }}
                  >
                    !
                  </span>
                </div>
              </button>
            </ul>
            <ul className="navbar-nav justify-content-end">
              <li className="nav-item">
                <div className="nav-item-padding-persona">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Person
                            personQuery="me"
                            className="person-element"
                            id="avatarElement"
                            showPresence
                            view={ViewType.image}
                            personCardInteraction={PersonCardInteraction.hover}
                          ></Person>
                        </td>
                        <td>
                          <label style={{ color: "white", marginLeft: "7px" }}>
                            {" "}
                            {props.userName}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default MainNavbar;
