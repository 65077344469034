import { activityLog } from "./activityLog";
import { complianceResult } from "./complianceResult";
import { M365GroupActivity } from "./m365GroupActivity";
import { spoTemplate } from "./spo-template";
import { SharePointActivity } from "./spoSiteActivity";
import { TeamsActivity } from "./teamsActivity";
import { YammerActivity } from "./yammerActivity";

export class resource {
  azureAdId: string = "";
  siteId:string = "";
  siteUrl: string = "";
  displayName: string = "";
  isGroupConnected: boolean = false;
  resourceType = "";
  classification: string = "";
  privacy: string = "";
  mailboxEnabled: boolean = false;
  mailbox: string = "";
  spoTemplateCode = "";
  complianceState: number = 0;
  renewDateFormatted: Date = new Date();
  lastUpdatedFormatted: Date = new Date();
  owners: string[] = [];
  members: string[] = [];
  teamsUrl: string = "";
  siteTemplate: spoTemplate = new spoTemplate();
  groupActivity?: M365GroupActivity = new M365GroupActivity();
  siteActivity?: SharePointActivity = new SharePointActivity();
  teamsActivity?: TeamsActivity = new TeamsActivity();
  yammerActivity?: YammerActivity = new YammerActivity();
  sensitivityLabel: string = "";
  membersCount: number = 0;
  externalMembersCount: number = 0;
  agsTracked: boolean = false;
  compliance: Array<complianceResult> = [];
  isCompliant: boolean = true;
  activityDataAvailable: boolean = false;
  renewActivityLog?: activityLog;
  setupToolFlag: string = "";
}
