import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  MgtPerson
} from "@microsoft/mgt-react";
import { left } from "@popperjs/core";
import React from "react";
import StatusCode from "status-code-enum";
import Swal from "sweetalert2";
import { apiScopes } from "../authConfig";
import { ChangeType } from "../enums";
import { errorsTypes } from "../errorTypes";
import { directoryObject } from "../objects/directoryObj";
import { resource } from "../objects/resource";
import {
  addSPOSiteOwner,
  removeSPOSiteOwner
} from "../services/resource-manager";
import {
  ConvertBytesToMB,
  ConvertDateStringToLongDate
} from "../utils";
import M365Card from "./m365-card";
import SPOSiteCard from "./spo-site-card";
import { toast } from "react-toastify";
import { OwnerManager } from "./owner-manager";
interface resourceDetailProps {
  ownedResource: resource;
  baseTabId: string;
  refreshDatasetFunction: (resourceID: string, changeType: ChangeType, value: any) => void;
}
/**
 * Represents the details of a single M365 or SPO resources, displayed by the resources grid
 */
export function ResourceDetailSPO(props: resourceDetailProps) {
  MgtPerson.config.useContactApis = true;
  const { instance, accounts } = useMsal();
  const accessTokenRequest = {
    scopes: apiScopes.resourceManagementScopes,
    account: accounts[0],
  };

  function addSiteAdministrator(ownerList:directoryObject[], resourceId: string):void{
    ownerList.forEach(currOwner => {
      Swal.showLoading();
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(async (accessTokenResponse) => {
          const toastLeaseRenewId = toast.loading(`Adding ${currOwner.userPrincipalName}`);
          let operationResult = await addSPOSiteOwner(
            accessTokenResponse.accessToken,
            resourceId,
            currOwner.userPrincipalName as string
          );
          Swal.close();
          if (operationResult === StatusCode.SuccessOK) {
            props.refreshDatasetFunction(
              props.ownedResource.siteUrl,
              ChangeType.AddOwner,
              currOwner.userPrincipalName as string
            );
            let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
            try {
              let buttonElement = document.getElementById(collapseButtonId);
              buttonElement?.click();
            } catch {
              console.log("Could not trigger tab collapse");
            }
            toast.update(toastLeaseRenewId, {
              render: "Operation Complete",
              type: "success",
              isLoading: false,
              autoClose: 8000,
            });
          } else {
            toast.error(errorsTypes.somethingWentWrong.errorSubtitle, {
              type: "error",
              isLoading: false,
              autoClose: 8000,
            });
          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            Swal.close();
            Swal.fire({
              titleText: errorsTypes.sessionExpired.errorTitle,
              text: errorsTypes.sessionExpired.errorSubtitle,
              icon: "error",
              willClose: () => {
                instance.acquireTokenRedirect(accessTokenRequest);
              },
            });
          }
          console.log(error);
        });
    });
  }
  function removeSiteAdministrator(ownerList:directoryObject[], resourceId: string): void {
    ownerList.forEach(currOwner => {
      Swal.showLoading();
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(async (accessTokenResponse) => {
          const toastLeaseRenewId = toast.loading(`Removing ${currOwner.userPrincipalName}`);
          let operationResult = await removeSPOSiteOwner(
            accessTokenResponse.accessToken,
            resourceId,
            currOwner.userPrincipalName as string
          );
          Swal.close();
          if (operationResult === StatusCode.SuccessOK) {
            if (
              ((window as any).CURRENT_USER_EMAIL as string).trim().toLowerCase() ===
              (currOwner.userPrincipalName as string).trim().toLowerCase()
            ) {
              props.refreshDatasetFunction(
                props.ownedResource.siteUrl,
                ChangeType.DeleteSite,
                currOwner.userPrincipalName as string
              );
            } else {
              props.refreshDatasetFunction(
                props.ownedResource.siteUrl,
                ChangeType.RemoveOwner,
                currOwner.userPrincipalName as string
              );
              let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
              try {
                let buttonElement = document.getElementById(collapseButtonId);
                buttonElement?.click();
              } catch {
                console.log("Could not trigger tab collapse");
              }
            }
            toast.update(toastLeaseRenewId, {
              render: "Operation Complete",
              type: "success",
              isLoading: false,
              autoClose: 8000,
            });
          } else {
            toast.error(errorsTypes.somethingWentWrong.errorSubtitle, {
              type: "error",
              isLoading: false,
              autoClose: 8000,
            });
          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            Swal.close();
            Swal.fire({
              titleText: errorsTypes.sessionExpired.errorTitle,
              text: errorsTypes.sessionExpired.errorSubtitle,
              icon: "error",
              willClose: () => {
                instance.acquireTokenRedirect(accessTokenRequest);
              },
            });
          }
          console.log(error);
        });
    });
  }
  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
      <div className="row">
        <ul className="nav nav-tabs">
          <li className="nav-item" role="presentation" style={{ borderColor: "#E9E9E9" }}>
            <button
              type="button"
              id={props.baseTabId + "-overview"}
              className="nav-link active nav-link-resource"
              data-bs-toggle="tab"
              data-bs-target={"#" + props.baseTabId + "-overview-content"}
              role="tab"
              aria-controls="home"
              aria-selected="true"
              style={{ fontWeight: "bold" }}
            >
              <i className="intelicon-contacts-outlined" style={{ fontWeight: "bold" }}></i>{" "}
              Overview
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id={props.baseTabId + "-owners"}
              className="nav-link nav-link-resource"
              data-bs-toggle="tab"
              data-bs-target={"#" + props.baseTabId + "-owners-content"}
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              style={{ fontWeight: "bold" }}
            >
              <i className="intelicon-primary-user-person-solid" style={{ fontWeight: "bold" }}></i>{" "}
              Site Collection Administrators
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id={props.baseTabId + "-activity"}
              className="nav-link nav-link-resource"
              data-bs-toggle="tab"
              data-bs-target={"#" + props.baseTabId + "-activity-content"}
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              style={{ fontWeight: "bold" }}
            >
              <i className="intelicon-activity-outlined" style={{ fontWeight: "bold" }}></i>{" "}
              Activity
            </button>
          </li>
        </ul>
        <div
          className="tab-content border-end border-bottom border-start"
          style={{ backgroundColor: "#E9E9E9" }}
        >
          <div
            className="tab-pane fade active show "
            id={props.baseTabId + "-overview-content"}
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {props.ownedResource.isGroupConnected ? (
              <M365Card ownedResource={props.ownedResource} />
            ) : (
              <SPOSiteCard ownedResource={props.ownedResource} />
            )}

            <br />
          </div>
          <div
            className="tab-pane"
            id={props.baseTabId + "-owners-content"}
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div style={{ paddingLeft: "14px" }}>
              <br />
              <OwnerManager
                currentOwners={props.ownedResource.owners}
                resourceId={props.ownedResource.siteUrl}
                isM365={false}
                addFunction={addSiteAdministrator}
                removeFunction={removeSiteAdministrator}
                isAGS={props.ownedResource.agsTracked}
              ></OwnerManager>
              <br />
            </div>
          </div>
          <div
            className="tab-pane"
            id={props.baseTabId + "-activity-content"}
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div
              style={{
                display: !props.ownedResource.activityDataAvailable === false ? "none" : "grid",
              }}
            >
              <br />
              <div className="alert alert-light" role="alert" style={{ backgroundColor: "white" }}>
                <h4 className="alert-heading">No activity was found for this space</h4>
                <p></p>
                <hr />
                <p className="mb-0">
                  Collaboration Spaces created 24 hours ago or less might no have activity records
                  available, please wait 24-48 hours for the information to be available
                </p>
              </div>
            </div>
            <div
              style={{
                display: props.ownedResource.activityDataAvailable === false ? "none" : "grid",
              }}
            >
              <br />
              <table className="table table-bordered">
                <tbody>
                  <tr style={{ backgroundColor: "#077568", color: "white" }}>
                    <th>SharePoint Activity Summary</th>
                  </tr>
                  <tr style={{ backgroundColor: "white" }}>
                    <td>
                      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Last Activity Date:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={ConvertDateStringToLongDate(
                                props.ownedResource.siteActivity?.lastActivityDate
                              )}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Page View Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.siteActivity?.pageViewCount}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              File Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.siteActivity?.fileCount}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Active File Count
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.siteActivity?.activeFileCount}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Allocated Storage:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value="25TB"
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Storage Used:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                ConvertBytesToMB(
                                  props.ownedResource.siteActivity?.storageUsedByte as string
                                ) + "MB"
                              }
                            />
                          </div>
                        </div>
                        <br />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceDetailSPO;
