import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import {
  ComboBox,
  Dropdown,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
  IComboBoxStyles,
  IDropdownOption,
  IStackTokens,
  Panel,
  PanelType,
  Separator,
  Shimmer,
  ShimmerElementType,
  Stack,
  Toggle,
  TooltipHost,
  mergeStyleSets,
  mergeStyles,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { ActionButton } from "@fluentui/react/lib/Button";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { Text } from "@fluentui/react/lib/Text";
import { sort } from "fast-sort";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { apiScopes } from "../authConfig";
import { ResourceGrid } from "../components/resources-grid";
import ResourceGridByProduct from "../components/resources-grid-by-prod";
import { errorsTypes } from "../errorTypes";
import { resource } from "../objects/resource";
import { getM365GroupActivity, getM365GroupOwners, getOwnedM365Groups, getOwnedStandaloneSPOSites } from "../services/resource-manager";
import { checkResourceCompliance, createAllResourcesList } from "../utils";
import { ChangeType, SensitivityLabel } from "../enums";
import { toast } from "react-toastify";

export const ResourceList: React.FunctionComponent = () => {
  const [gridFullyRendered, setGridFullyRendered] = useState(false);
  const stackTokens: IStackTokens = { childrenGap: 4 };
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const iconClass = mergeStyles({
    fontSize: 40,
    height: 40,
    width: 40,
    margin: "0 10px",
  });
  const iconClassFilter = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: "0 5px",
  });
  // Left this variable here for future use and reference
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classNames = mergeStyleSets({
    outlookColor: [{ color: "#0078d4" }, iconClass],
    sharepointColor: [{ color: "#077568" }, iconClass],
    teamsColor: [{ color: "#6264a7" }, iconClass],
    yammerColor: [{ color: "#106ebe" }, iconClass],
    chevronColor: [{ color: "#808080" }, iconClass],
    intelBlue: [{ color: "#0054AE" }, iconClassFilter],
  });
  const [showAllSpaces, setShowAllSpaces] = useState<boolean>(false);
  const [useAscendantSort, setUseAscendantSort] = useState<boolean>(true);
  const [showTeams, setShowTeams] = useState<boolean>(true);
  const [showSPO, setShowSPO] = useState<boolean>(true);
  const [showYammer, setShowYammer] = useState<boolean>(true);
  const [showCompliance, setShowCompliance] = useState<boolean>(true);
  const [resourceLoadComplete, toggleResourceLoadComplete] = useState<boolean>(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState<string>("displayName");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const didLoadData = useRef(false);
  const { instance, inProgress, accounts } = useMsal();
  let [baseResourceList, setBaseResourceList] = useState<Array<resource> | null>(null);
  //This is the base list, filters will be applied to the current list while the base list will remain in memory in case the filters need to reset baseResourceList
  let [currentResourceList, setCurrentResourceList] = useState<Array<resource>>([]);
  const options: IComboBoxOption[] = [
    { key: "displayName", text: "Workspace Name" },
    { key: "resourceType", text: "Workspace Type" },
    { key: "complianceState", text: "Status" },
    { key: "renewDateFormatted", text: "Expiration Date" },
    { key: "sensitivityLabel", text: "Sensitivity Label" },
  ];
  const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };
  const comboBoxRef = React.useRef<IComboBox>(null);
  const getResourceLoadingShimmer = (): JSX.Element => {
    let shimmerRowsList = [];
    for (let index = 0; index < 10; index++) {
      shimmerRowsList.push(
        <Shimmer
          key={index}
          shimmerElements={[
            { type: ShimmerElementType.circle, height: 30 },
            { type: ShimmerElementType.gap, width: "1%", height: 30 },
            { type: ShimmerElementType.line, height: 30 },
          ]}
        ></Shimmer>
      );
    }

    return <div>{shimmerRowsList}</div>;
  };



  function _handleGroupedViewChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    setShowAllSpaces(checked as boolean);
  }
  const _handleTeamsDropDownVisibility = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      if(option.key === 1){
        setShowTeams(true);
      }
      else{
        setShowTeams(false);
      }
    }
  };
  const _handleSharePointDropDownVisibility = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      if(option.key === 1){
        setShowSPO(true);
      }
      else{
        setShowSPO(false);
      }
    }
  };
  const _handleYammerDropDownVisibility = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      if(option.key === 1){
        setShowYammer(true);
      }
      else{
        setShowYammer(false);
      }
    }
  };
  const _handleComplianceDropDownVisibility = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      if(option.key === 1){
        setShowCompliance(true);
      }
      else{
        setShowCompliance(false);
      }
    }
  };
  const _handleSortDropDownDirection= (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      if(option.key === 1){
        setUseAscendantSort(true);
      }
      else{
        setUseAscendantSort(false);
      }
    }
  };
  const _handleSortColumnChange: IComboBoxProps["onChange"] = (event, option) => {
    setSelectedSortColumn(option?.key as string);
  };
  function clearSearch(): void {
    let filterResourceList: Array<resource> = [...(baseResourceList as Array<resource>)];
    setSearchTerm("");
    setCurrentResourceList(filterResourceList);
  }
  function applySelectedFilters(searchCriteria: string = ""): void {
    if (baseResourceList !== null) {
      let filterResourceList: Array<resource> = [...baseResourceList];
      //Apply search filter
      if (searchCriteria !== "") {
        setSearchTerm(searchCriteria);
        filterResourceList = filterResourceList.filter((element) =>
          element.displayName.toLowerCase().includes(searchCriteria.toLowerCase())
        );
      } else if (searchTerm !== "") {
        filterResourceList = filterResourceList.filter((element) =>
          element.displayName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      //Apply additional filters one by one
      if (showTeams === false) {
        filterResourceList = filterResourceList.filter(
          (element) => element.resourceType !== "Teams"
        );
      }

      if (showSPO === false) {
        filterResourceList = filterResourceList.filter(
          (element) => element.resourceType !== "SPO" && element.resourceType !== "CSPO"
        );
      }

      if (showYammer === false) {
        filterResourceList = filterResourceList.filter(
          (element) => element.resourceType !== "Yammer"
        );
      }
      if (showCompliance === false) {
        filterResourceList = filterResourceList.filter((element) => element.isCompliant === false);
      }
      if (useAscendantSort) {
        if (selectedSortColumn === "displayName") {
          filterResourceList = sort(filterResourceList).asc((element) => element.displayName);
        }
        if (selectedSortColumn === "resourceType") {
          filterResourceList = sort(filterResourceList).asc((element) => element.resourceType);
        }
        if (selectedSortColumn === "complianceState") {
          filterResourceList = sort(filterResourceList).asc((element) => element.isCompliant);
        }
        if (selectedSortColumn === "renewDateFormatted") {
          filterResourceList = sort(filterResourceList).asc(
            (element) => element.renewDateFormatted
          );
        }
        if (selectedSortColumn === "sensitivityLabel") {
          filterResourceList = sort(filterResourceList).asc((element) => element.sensitivityLabel);
        }
      } else {
        if (selectedSortColumn === "displayName") {
          filterResourceList = sort(filterResourceList).desc((element) => element.displayName);
        }
        if (selectedSortColumn === "resourceType") {
          filterResourceList = sort(filterResourceList).desc((element) => element.resourceType);
        }
        if (selectedSortColumn === "complianceState") {
          filterResourceList = sort(filterResourceList).desc((element) => element.isCompliant);
        }
        if (selectedSortColumn === "renewDateFormatted") {
          filterResourceList = sort(filterResourceList).desc(
            (element) => element.renewDateFormatted
          );
        }
        if (selectedSortColumn === "sensitivityLabel") {
          filterResourceList = sort(filterResourceList).desc((element) => element.sensitivityLabel);
        }
      }
      setCurrentResourceList(filterResourceList);
    }
  }
  const refreshMainDataset = (resourceID: string = "", changeType: ChangeType, value: any) => {
    toggleResourceLoadComplete(false);
    let filterResourceList: Array<resource> = [...(baseResourceList as resource[])];
    filterResourceList.forEach((element) => {
      if (element.azureAdId === resourceID || element.siteUrl === resourceID) {
        if (changeType === ChangeType.UpdateLease) {
          let tempDate = new Date();
          tempDate.setMonth(tempDate.getMonth() + 6);
          element.renewDateFormatted = tempDate;
          console.log(element);
        }
        if (changeType === ChangeType.UpdateLeaseInternal) {
          let tempDate = new Date();
          tempDate.setMonth(tempDate.getMonth() + 6);
          element.renewDateFormatted = tempDate;
          element.sensitivityLabel = SensitivityLabel.InternalOnly;
          console.log(element);
        }
        if (changeType === ChangeType.AddOwner) {
          element.owners.push(value as string);
        }
        if (changeType === ChangeType.RemoveOwner) {
          let arrayWithoutOwner = element.owners.filter(function (owner) {
            return owner !== (value as string);
          });
          element.owners = arrayWithoutOwner;
          console.log(element);
        }
        element.compliance = checkResourceCompliance(element);
        element.isCompliant = element.compliance.every(
          (compResult) => compResult.isCompliant === true
        );
      }
    });
    if (changeType === ChangeType.DeleteGroup) {
      filterResourceList = filterResourceList.filter((o) => o.azureAdId !== resourceID);
    }
    if (changeType === ChangeType.DeleteSite) {
      filterResourceList = filterResourceList.filter((o) => o.siteUrl !== resourceID);
    }
    setBaseResourceList(filterResourceList);
    setCurrentResourceList(filterResourceList);
    toggleResourceLoadComplete(true);
  };
  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("resourceid") && searchParams.has("isconnected")) {
      let isConnected = searchParams.get("isconnected");
      let resourceId = searchParams.get("resourceid");
      const elementsWithTwoDataAttributes = document.querySelectorAll<HTMLButtonElement>(
        `[data-resourceid="${resourceId}"][data-isconnected="${isConnected}"]`
      );
      elementsWithTwoDataAttributes.forEach((element) => {
        if (!gridFullyRendered) {
          element.click();
          setTimeout(() => {
            element.scrollIntoView();
          }, 1000);
          setGridFullyRendered(true);
        }
      });
    }
  });
  useEffect(() => {
    async function fetchData() {
      try{
        setBaseResourceList([]);
      // You can await here
      const RMTAccessTokenRequest = {
        scopes: apiScopes.resourceManagementScopes,
        account: accounts[0],
      };
      let RMTAccessTokenResponse = await instance.acquireTokenSilent(RMTAccessTokenRequest);
      const [m365GroupsREsult, spoGroupsResult] = await Promise.all([
        getOwnedM365Groups(RMTAccessTokenResponse.accessToken),
        getOwnedStandaloneSPOSites(RMTAccessTokenResponse.accessToken)
      ]);
      let unifiedResourceList = createAllResourcesList(spoGroupsResult,m365GroupsREsult);
      unifiedResourceList = sort(unifiedResourceList).asc(
        (element) => element.displayName
      );
      unifiedResourceList.forEach(async (element) => {
        element.compliance = checkResourceCompliance(element);
        element.isCompliant = element.compliance.every(
          (compResult) => compResult.isCompliant === true
        );
      });
      unifiedResourceList = sort(unifiedResourceList).asc(
        (element) => element.renewDateFormatted
      );
      
      setBaseResourceList(unifiedResourceList);
      setCurrentResourceList(unifiedResourceList);
      toggleResourceLoadComplete(true);
      try{
        const toastLeaseRenewId = toast.loading("Loading more information, it can take a while");
        let ownerTaskList = await m365GroupsREsult.map(async group => {
          //Populate Owners
          try{
            group.owners = await getM365GroupOwners(RMTAccessTokenResponse.accessToken,group.graphEntry?.id as string);
          }
          catch{
            group.owners = [];
          }
          //Populate group activity
          try{
            group.activity = await getM365GroupActivity(RMTAccessTokenResponse.accessToken,group.graphEntry?.id as string);
          }
          catch{
            console.log(`No activity found for group ${group.graphEntry?.id}`);
          }
      });
      await Promise.all(ownerTaskList);
        let unifiedResourceList = createAllResourcesList(spoGroupsResult,m365GroupsREsult);
        unifiedResourceList = sort(unifiedResourceList).asc(
          (element) => element.displayName
        );
        unifiedResourceList.forEach(async (element) => {
          element.compliance = checkResourceCompliance(element);
          element.isCompliant = element.compliance.every(
            (compResult) => compResult.isCompliant === true
          );
        });
        unifiedResourceList = sort(unifiedResourceList).asc(
          (element) => element.renewDateFormatted
        );
        
        setBaseResourceList(unifiedResourceList);
        setCurrentResourceList(unifiedResourceList);
        toast.update(toastLeaseRenewId, {
          render: "Done",
          type: "success",
          isLoading: false,
          autoClose: 8000,
        });
      }
      catch{}
      }
      catch {
        toggleResourceLoadComplete(true);
        Swal.fire(
          errorsTypes.somethingWentWrong.errorTitle,
          errorsTypes.somethingWentWrong.errorSubtitle,
          "error"
        );
      }
    }
    if (!baseResourceList && inProgress === InteractionStatus.None) {
      fetchData();
    }
  }, [accounts, baseResourceList, inProgress, instance, resourceLoadComplete]);
  return (
    <div className="container-fluid responsive-padding">
      <br></br>
      <h1>Owned Workspaces</h1>
      <AuthenticatedTemplate>
        <Panel
          headerText="Filter Settings"
          isOpen={isOpen}
          onDismiss={dismissPanel}
          type={PanelType.smallFixedNear}
          // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
          closeButtonAriaLabel="Close"
        >
          <Stack tokens={stackTokens}>
            <Separator></Separator>
            <Toggle
              label="Group spaces by product"
              onText="On"
              offText="Off"
              checked={showAllSpaces}
              onChange={_handleGroupedViewChange}
            />
            <Separator></Separator>
            <Text variant="mediumPlus">Visibility</Text>

            <Dropdown
              placeholder="Select an option"
              label="Teams"
              options={[
                { key: 1, text: "Show all teams" },
                { key: 0, text: "Hide all teams" },
              ]}
              selectedKey={Number(showTeams)}
              onChange={_handleTeamsDropDownVisibility}
            />
            <Dropdown
              placeholder="Select an option"
              label="SharePoint"
              options={[
                { key: 1, text: "Show all sites" },
                { key: 0, text: "Hide all sites" },
              ]}
              selectedKey={Number(showSPO)}
              onChange={_handleSharePointDropDownVisibility}
            />
            <Dropdown
              placeholder="Select an option"
              label="Viva Engage"
              options={[
                { key: 1, text: "Show all communities" },
                { key: 0, text: "Hide all communities" },
              ]}
              selectedKey={Number(showYammer)}
              onChange={_handleYammerDropDownVisibility}
            />
            <Dropdown
              placeholder="Select an option"
              label="Compliance"
              options={[
                { key: 1, text: "Show compliant and non compliant" },
                { key: 0, text: "Show only non compliant" },
              ]}
              selectedKey={Number(showCompliance)}
              onChange={_handleComplianceDropDownVisibility}
            />
            <Separator></Separator>
            <Text variant="mediumPlus">Sorting</Text>
            <ComboBox
              componentRef={comboBoxRef}
              defaultSelectedKey="0"
              label="Order By"
              options={options}
              styles={comboBoxStyles}
              onChange={_handleSortColumnChange}
              selectedKey={selectedSortColumn}
            />
            <Dropdown
              placeholder="Select an option"
              label="Sort Direction"
              options={[
                { key: 1, text: "Ascendant" },
                { key: 0, text: "Descendant" },
              ]}
              selectedKey={Number(useAscendantSort)}
              onChange={_handleSortDropDownDirection}
            />
            <Separator></Separator>
            <button
              type="button"
              className="btn btn-primary me-1"
              onClick={() => {
                applySelectedFilters();
                dismissPanel();
              }}
            >
              Apply Filters
            </button>
          </Stack>
        </Panel>
        <div
          className="container-fluid text-center ms-Fabric"
          style={{ paddingLeft: "0rem", paddingRight: "0rem" }}
        >
          <div className="row">
            <div className="col">
              <br></br>
              <TooltipHost content="Please type a search term and then press the ENTER key">
                <SearchBox
                  placeholder="Search Workspaces"
                  onClear={() => {
                    clearSearch();
                  }}
                  onSearch={(newValue: String) => {
                    if (newValue !== null && newValue.trim() !== "" && newValue.length >= 2) {
                      applySelectedFilters(newValue.trim());
                    } else if (newValue !== null && newValue === "") {
                      clearSearch();
                    }
                  }}
                />
              </TooltipHost>
            </div>
          </div>
          <div className="row">
            <div className="col" style={{ textAlign: "start", marginTop: "5px" }}>
              <ActionButton
                iconProps={{ iconName: "FilterSettings" }}
                allowDisabledFocus
                onClick={openPanel}
              >
                Filter Options
              </ActionButton>
            </div>
          </div>
          <div className="row" style={{ display: resourceLoadComplete ? "block" : "none" }}>
            <div className="col">
              {!showAllSpaces ? (
                <ResourceGrid
                  ownedResources={currentResourceList}
                  resourceType="Any"
                  refreshDatasetFunction={refreshMainDataset}
                />
              ) : (
                <ResourceGridByProduct
                  ownedResources={currentResourceList}
                  refreshDatasetFunction={refreshMainDataset}
                />
              )}
            </div>
          </div>
          <div style={{ display: !resourceLoadComplete ? "block" : "none" }}>
            <br></br>
            <div className="card" style={{ margin: "15px" }}>
              <div className="card-body">{getResourceLoadingShimmer()}</div>
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>You are not signed in! Please sign in.</p>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default ResourceList;
